import { Injectable } from '@angular/core'
import { AlertsService } from '../alerts/alerts.service'
import { SpinnerService } from '../spinner/spinner.service'
import { ToastsService } from '../toasts/toasts.service'
import { HttpClient } from '@angular/common/http'

@Injectable({
	providedIn: 'root'
})
export class ApiService {
  baseUrl = 'https://apijadcup.gradspace.org/api/'
  // baseUrl = 'http://localhost:5020/api/'
  // baseUrl = 'https://pc.nzcups.co.nz/api/'
  appPages: any = []
  urlEmployeeLogin = 'Employee/EmployeeLogin'
  urlGetAllCustomer = 'Customer/GetAllCustomer'
  allCustomerData
  selectedCustomerData
  urlGetOrder = "SalesOrder/GetOrderById?id="
  urlGetAllCustOrders = 'SalesOrder/GetByCust?customerId='
  selectedCustomerOrders
  selectedOrderDetail
  urlGetAllCustQuotes = 'Quotation/GetQuotationByCustomerId?id='
  selectedCustomerQuotes
  selectedQuoteDetail
  urlCreateCustomer = 'Customer/AddCustomer'
  urlDeliveryCity = 'City/GetAllCity'
  allDeliveryCityData
  urlDeliveryMethod = 'DeliveryMethod/GetAllDeliveryMethod'
  allDeliveryMethodData
  urlCourier = 'Courier/GetAllCourier'
  allCourierData  
  urlGetAllShowingProducts = 'ProductForShowing/GetAllShowingProducts'
  allShowingProducts
  urlGetAllProductsByCustomerId = 'Product/GetProductByCustomerIdWithQuota?id='
  allProductsByCustomerId
  urlGetAllQuotationsByCustomerId = 'Quotation/GetQuotationByCustomerId?id='
  allQuotationsByCustomerId
  urlGetAllProductOptions = 'ProductOption/GetAllProductOption'
  allProductOptions
  urlCreateOrder = 'SalesOrder/AddOrder'
  urlUpdateFullOrder = 'SalesOrder/UpdateFullOrder'
  isOnlineUser: boolean
  urlOnlineUserLogin = 'OnlineUser/OnlineUserLogin'
  urlOnlineUserCustomerData = 'Customer/GetCustomerById?id='
  urlSinglePackingSlip = 'Dispatching/GetSingleDispatching?packingSlipNo='
  urlUploadImage = 'Common/UploadImage'
  urlUploadImgsDispatching = 'Dispatching/UploadImgsDispatching'
  urlCompleteDispatching = 'Dispatching/CompleteDispatching'
  boxByBarcode
  urlGetBoxByBarcode = 'Box/GetBoxByBarCode?barCode='
  urlGetExpiryByBarCode = 'Box/GetExpiryByBarCode?barCode='
  urlGenerateByExpiryBarcode = 'Box/GenerateByExpiryBarcode'
  boxesByPlateCode
  urlGetBoxesByPlateId = 'Plate/GetBoxByPlateId?plateId='
  urlGetBoxesByPlateCode = 'Plate/GetBoxByPlateCode?plateCode='
  rawMaterialBoxByBoxcode
  urlGetRawMaterialBoxByBoxcode = 'RawMaterialBox/GetSingleRawMaterialBox?boxCode='
  rawMaterialBoxesByPlateCode
  urlGetRawMaterialBoxesByPlateCode = 'Plate/GetRawMaterialBoxByPlateCode?plateCode='
  plateByPlateCode
  urlGetPlateByPlateCode = 'Plate/GetPlateByPlateCode?code='
  PickupProduct
  urlGetPickupProduct = 'SalesOrder/GetPickupProduct'
  urlMoveBox = 'PlateBox/MoveBox'
  ProductLocation
  urlGetGetLocationByid = 'Product/GetLocationByid?id='
  urlPutWareshouseScanerPlateBox = 'PlateBox/WareshouseScanerPlateBox'
  urlPutPackagingScanerPlateBox = 'PlateBox/PackagingScanerPlateBox'
  urlPostPickingDispatch = 'Dispatching/PickingDispatch'
  urlAddToWarehouse = 'TempZone/AddToWarehouse'
  urlGetPlateByShelfCode = 'Shelf/GetShelfCellByCode?code='
  urlAddShelfCellByCode = "ShelfPlate/AddShelfCellByCode"
  urlDeletePalletById = "Plate/DeletePlate"
  urlGetIsBatchProduct = 'Product/isBatchProduct?productId='
  urlPutMergePlate = 'Plate/MergePallet'
  urlPutMergePlateBatchProduct = 'Plate/MergePalletBatchProduct'
  urlPutBoxQuantity = 'Box/UpdateStockBoxQuantity'
  urlMarkDefects = 'Box/MarkDefects'
  urlUnMarkDefects = 'Box/UnMarkDefects'
  urlMovePlateToTempZone = 'TempZone/MovePlateToTempZone'
  urlGetZoneType = 'TempZone/GetZoneType';
  constructor(
    private alertsService: AlertsService,
    private spinnerService: SpinnerService,
    private toastsService: ToastsService,
    private http: HttpClient,
  ) {}

  isSpinnerEnable(url) {
    return [
      this.urlEmployeeLogin,
      this.urlOnlineUserLogin,
      this.urlCreateCustomer,
      this.urlCreateOrder,
      this.urlUpdateFullOrder,
      this.urlCompleteDispatching,
      this.urlMoveBox,      
      this.urlPutWareshouseScanerPlateBox,    
      this.urlPutPackagingScanerPlateBox,       
      this.urlPostPickingDispatch,      
      this.urlAddToWarehouse,       
      this.urlAddShelfCellByCode,       
      this.urlPutMergePlate,       
      this.urlPutMergePlateBatchProduct,      
      this.urlPutBoxQuantity,
    ].some(row => url.includes(row))
  }
   // ---------------Auth-------------------
  userLogin(data) {
    return this.apiRequest({
      url: this.urlEmployeeLogin,
      method: 'post',
      data
    })
  }

  onlineUserLogin(data) {
    return this.apiRequest({
      url: this.urlOnlineUserLogin,
      method: 'post',
      data
    })
  }
async GetZoneType() {
  return await this.apiRequest({
    url: `${this.urlGetZoneType}`,
    method: 'get'
  });
}
  // ---------------Customer Data-------------------
  async getOnlineUserCustomerData(id: any) {
    this.selectedCustomerData = await this.apiRequest({
      url: this.urlOnlineUserCustomerData + id
    })
    console.log(this.selectedCustomerData)
    this.selectedCustomerData.displayName =  this.selectedCustomerData.company + ' - ' + this.selectedCustomerData.customerCode
    return this.selectedCustomerData
  }
  async MovePlateToTempZone(plateId: string, zoneTypeId: string) {
    return await this.apiRequest({
      url: `${this.urlMovePlateToTempZone}?plateId=${encodeURIComponent(plateId)}&zoneTypeId=${encodeURIComponent(zoneTypeId)}`,
      method: 'put'
    });
  }
  // ---------------Customer List-------------------
  async getAllCustomers() {
    this.allCustomerData = await this.apiRequest({
      url: this.urlGetAllCustomer
    })
    this.allCustomerData = this.allCustomerData.map(row => ({...row, displayName: row.company + ' - ' + row.customerCode}))
    return this.allCustomerData
  }
  async GetIsBatchProduct(productId: any) {
    return await this.apiRequest({
      url: this.urlGetIsBatchProduct + productId
    })
  }
  async PutMergePlate(plateId1: any,plateId2: any) {
    return await this.apiRequest({
      url: this.urlPutMergePlate + '?plateId1=' + plateId1 + '&plateId2=' + plateId2,
      method: 'put',
    })
  }
  async DeletePallet(plateId: any,) {
    return await this.apiRequest({
      url: this.urlDeletePalletById + '?id=' + plateId,
      method: 'delete',
    })
  }
  async MarkDefects(ids: any, Notes: string, empNotes: string) {
    return await this.apiRequest({
      url: `${this.urlMarkDefects}?Notes=${encodeURIComponent(Notes)}&empNotes=${encodeURIComponent(empNotes)}`,
      method: 'put',
      data :ids
    })
  }
async UnMarkDefects(ids: any,) {
    return await this.apiRequest({
      url: this.urlUnMarkDefects ,
      method: 'put',
      data : ids
    })
  }
  async PutMergePlateBatchProduct(plateId1: any,plateId2: any,productId: number,qty: number) {
    return await this.apiRequest({
      url: this.urlPutMergePlateBatchProduct + '?plateId1=' + plateId1 + '&plateId2=' + plateId2+'&productId=' + productId + '&qty=' + qty,
      method: 'put',
    })
  }
  // ---------------Customer Order List-------------------
  // SalesOrder/GetOrderById?id=' + orderId,
  getOrder(orderId) {    return this.apiRequest({
    url: this.urlGetOrder + orderId
  })
}

  getAllCustOrders() {    return this.apiRequest({
      url: this.urlGetAllCustOrders + this.selectedCustomerData?.customerId
    })
  }

  // ---------------Customer Quote List-------------------
  getAllCustQuotes() {
    return this.apiRequest({
      url: this.urlGetAllCustQuotes + this.selectedCustomerData?.customerId
    })
  }

  // ---------------Create Customer-------------------
  createCustomer(data) {
    return this.apiRequest({
      url: this.urlCreateCustomer,
      method: 'post',
      data
    })
  }
//  urlPutBoxQuantity = 'Box/UpdateStockBoxQuantity'   boxId=' + rowData.boxId + '&quantity=' + newQuantity,
  updateBoxQuantity(boxId, newQuantity) {
    return this.apiRequest({
      url: this.urlPutBoxQuantity + '?boxId=' + boxId + '&quantity=' + newQuantity,
      method: 'put',
    })
  }
  // ---------------Products List-------------------
  async getAllShowingProducts() {
    this.allShowingProducts = await this.apiRequest({
      url: this.urlGetAllShowingProducts
    })
    return this.allShowingProducts
  }

  // ---------------Create Order-------------------
  async getAllDeliveryCity() {
    this.allDeliveryCityData = await this.apiRequest({
      url: this.urlDeliveryCity
    })
    return this.allDeliveryCityData
  }

  async getAllDeliveryMethod() {
    this.allDeliveryMethodData = await this.apiRequest({
      url: this.urlDeliveryMethod
    })
    return this.allDeliveryMethodData
  }
  async getAllCourier() {
    this.allCourierData = await this.apiRequest({
      url: this.urlCourier
    })
    return this.allCourierData
  }
  async getAllProductOptions() {
    this.allProductOptions = await this.apiRequest({
      url: this.urlGetAllProductOptions
    })
    return this.allProductOptions
  }

  async getAllProductsByCustomerId(id) {
    this.allProductsByCustomerId = await this.apiRequest({
      url: this.urlGetAllProductsByCustomerId + id
    })
    this.allProductsByCustomerId = this.allProductsByCustomerId
      .map(row => ({...row, 
        displayName: row.baseProduct?.packagingType?.quantity + 'pcs - ' + row.productName,
        fakeName:null
      }))
    return this.allProductsByCustomerId
  }

  async getAllQuotationsByCustomerId(id) {
    this.allQuotationsByCustomerId = await this.apiRequest({
      url: this.urlGetAllQuotationsByCustomerId + id + '&draft=0'
    })
    return this.allQuotationsByCustomerId
  }

  createOrder(data) {
    return this.apiRequest({
      url: this.urlCreateOrder,
      method: 'post',
      data
    })
  }

  async getSinglePackingSlip(packingSlipNo) {
    this.allQuotationsByCustomerId = await this.apiRequest({
      url: this.urlSinglePackingSlip + packingSlipNo
    })
    return this.allQuotationsByCustomerId
  } 
  updateFullOrder(data) {
    return this.apiRequest({
      url: this.urlUpdateFullOrder,
      method: 'put',
      data
    })
  }

  // ---------------Dispatching-------------------

  uploadImgDispatching(data) {
    return this.apiRequest({
      url: this.urlUploadImgsDispatching,
      method: 'put',
      data
    })
  }

  completeDispatching(data) {
    return this.apiRequest({
      url: this.urlCompleteDispatching,
      method: 'put',
      data
    })
  }

  uploadImg(data) {
    return this.http.post( this.baseUrl + this.urlUploadImage, data ,{responseType:'text'})
    .toPromise()
  }

  // ---------------Getting boxes or raw material boxes by-------------------
  async getBoxByBarcode(barcode) {
    this.boxByBarcode = await this.apiRequest({
      url: this.urlGetBoxByBarcode + barcode
    })
    return this.boxByBarcode
  }
  async getExpiryByBarCode(barcode) {
    let boxByBarcode = await this.apiRequest({
      url: this.urlGetExpiryByBarCode + barcode
    })
    return boxByBarcode
  }
  async getBoxesByPlateCode(plateCode) {
    this.boxesByPlateCode = await this.apiRequest({
      url: this.urlGetBoxesByPlateCode + plateCode
    })
    return this.boxesByPlateCode
  }
  async getBoxesByPlateId(plateId) {
    this.boxesByPlateCode = await this.apiRequest({
      url: this.urlGetBoxesByPlateId + plateId
    })
    return this.boxesByPlateCode
  }
  async getRawMaterialBoxByBoxcode(boxcode) {
    this.rawMaterialBoxByBoxcode = await this.apiRequest({
      url: this.urlGetRawMaterialBoxByBoxcode + boxcode
    })
    return this.rawMaterialBoxByBoxcode
  }

  async getRawMaterialBoxesByPlateCode(plateCode) {
    this.rawMaterialBoxesByPlateCode = await this.apiRequest({
      url: this.urlGetRawMaterialBoxesByPlateCode + plateCode
    })
    return this.rawMaterialBoxesByPlateCode
  }

  async getPlateByPlateCode(plateCode) {
    this.plateByPlateCode = await this.apiRequest({
      url: this.urlGetPlateByPlateCode + plateCode
    })
    return this.plateByPlateCode
  }
  async getPlateByShelfCode(shelfCode) {
    return await this.apiRequest({
      url: this.urlGetPlateByShelfCode + shelfCode
    })
  }  //urlAddShelfCellByCode
  AddShelfCellByCode(data) {
    return this.apiRequest({
      url: this.urlAddShelfCellByCode,
      method: 'post',
      data
    })
  }  
  async getPickupProduct() {
    this.PickupProduct = await this.apiRequest({
      url: this.urlGetPickupProduct
    })
    return this.PickupProduct
  }
  async getLocationByProductId(productId) {
    this.ProductLocation = await this.apiRequest({
      url: this.urlGetGetLocationByid + productId
    })
    return this.ProductLocation
  }  
  GenerateByExpiryBarcode(data) {
    return this.apiRequest({
      url: this.urlGenerateByExpiryBarcode ,
      method: 'post',
      data
    })
  }
  PutWareshouseScanerPlateBox(data) {
    return this.apiRequest({
      url: this.urlPutWareshouseScanerPlateBox,
      method: 'put',
      data
    })
  }
  PutPackagingScanerPlateBox(data) {
    return this.apiRequest({
      url: this.urlPutPackagingScanerPlateBox,
      method: 'put',
      data
    })
  }//
  PostPickingDispatch(data) {
    return this.apiRequest({
      url: this.urlPostPickingDispatch,
      method: 'post',
      data
    })
  }//
  AddToWarehouse(employeeId,plateNo) {
    return this.apiRequest({
      url: this.urlAddToWarehouse+"?employeeId="+employeeId+"&plateNo="+plateNo,
      method: 'post',
    })
  }  
  moveBox(data) {
    return this.apiRequest({
      url: this.urlMoveBox,
      method: 'put',
      data
    })
  }

  // ---------------Common api handling-------------------
  async apiRequest(args) {
    // console.log(args.data)
    let spinner
    if (this.isSpinnerEnable(args.url)) {
      spinner = await this.spinnerService.spinner()
    }
    try {
      const result = await this.getResultFromHttpRequest(args)
      return this.handleSucc(result)
    } catch (e) {
      if (spinner) {
        await spinner.dismiss()
      }
      await this.handleErr(e)
    } finally {
      if (this.isSpinnerEnable(args.url)) {
        if (spinner) {
          await spinner.dismiss()
        }
      }
    }
  }

  getResultFromHttpRequest(args) {
    const baseUrl = args.url.includes('http') ? '' : this.baseUrl
    console.log(args)
    return this.http.request(args.method || 'get', baseUrl + args.url, {
      body: args.data
    })
    .toPromise()
  }

  handleSucc(result) {
    return result.data
  }

  async handleErr(e) {
    console.log('catched error from api.service.ts')
    console.log(e)
    let message = 'Please contact administrator'
    if (e.status !== 200) {
      if (e.error?.message?.message) {
        message = e.error.message.message
      }
    }
    const clicked = this.toastsService.failedToast(message)
    console.log(clicked)
  }
}
