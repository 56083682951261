import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'customer-list',
    loadChildren: () => import('./pages/customer-list/customer-list.module').then(m => m.CustomerListPageModule)
  },
  {
    path: 'create-customer',
    loadChildren: () => import('./pages/create-customer/create-customer.module').then(m => m.CreateCustomerPageModule)
  },
  {
    path: 'create-order/create',
    loadChildren: () => import('./pages/create-order/create-order.module').then(m => m.CreateOrderPageModule)
  },
  {
    path: 'create-order/edit',
    loadChildren: () => import('./pages/create-order/create-order.module').then(m => m.CreateOrderPageModule)
  },
  {
    path: 'my-products-list',
    loadChildren: () => import('./pages/my-products-list/my-products-list.module').then( m => m.MyProductsListPageModule)
  },
  {
    path: 'all-products',
    loadChildren: () => import('./pages/all-products/all-products.module').then( m => m.AllProductsPageModule)
  },
  {
    path: 'packing-slip-info',
    loadChildren: () => import('./pages/packing-slip-info/packing-slip-info.module').then( m => m.PackingSlipInfoPageModule)
  },
  {
    path: 'packing-slip-records',
    loadChildren: () => import('./pages/packing-slip-records/packing-slip-records.module').then( m => m.PackingSlipRecordsPageModule)
  },
  {
    path: 'relocate-boxes',
    loadChildren: () => import('./relocate-boxes/relocate-boxes.module').then( m => m.RelocateBoxesPageModule)
  },
  {
    path: 'pakaging-scanner/:id',
    loadChildren: () => import('./pages/production-scanner/production-scanner.module').then( m => m.ProductionScannerPageRoutingModule)
  },
  {
    path: 'to-warehouse-scanner',
    loadChildren: () => import('./pages/to-warehouse-scanner/to-warehouse-scanner.module').then( m => m.ToWarehoueScannerPageRoutingModule)
  },  
  {
    path: 'product-pickup',
    loadChildren: () => import('./pages/product-pickup/product-pickup.module').then( m => m.ProductPickupPageRoutingModule)
  },  
  {
    path: 'box-pickup/:id',
    loadChildren: () => import('./pages/box-pickup/box-pickup.module').then( m => m.BoxPickupPageRoutingModule)
  },
  {
    path: 'item-scanner',
    loadChildren: () => import('./pages/item-scanner/item-scanner.module').then( m => m.ItemScannerPageRoutingModule)
  },
  {
    path: 'pallet-relocate',
    loadChildren: () => import('./pages/pallet-relocate/pallet-relocate.module').then( m => m.PalletRelocatePageRoutingModule)
  },
  {
    path: 'pallet-merge',
    loadChildren: () => import('./pages/pallet-merge/pallet_merge').then( m => m.PalletMergePageRoutingModule)
  } ,
  {
    path: 'mark-defect',
    loadChildren: () => import('./pages/mark-defect/mark-defect.module').then( m => m.MarkDefectPageRoutingModule)
  }          
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
